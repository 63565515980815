/* eslint-disable no-restricted-globals */

'use strict';

var clientSideValidation = require('../components/common/clientSideValidation');
var coreRegister = require('org/login/register');
var layout = require('org/layout').init();

/**
 * sets the 'registered' cookie
 */
function setRegisteredCookie() {
    var d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toGMTString();

    document.cookie = `showRegisteredModal=true; ${expires}; secure='on'; path=/;`;
}

/**
 * On Submitting login on modal
 */
function submitCreateAccount() {
    $('body').on('click', '.js-createaccount-button', function (e) {
        e.preventDefault();
        $('.b-registration-error').addClass('hide');
        var $this = $(this).closest('form');
        var form = $(this).closest('form');
        var url = form.attr('action');
        var type = form.attr('method');
        var formdata = form.serialize();
        var registerForm = $('input[name="initializeRegisterForm"]');
        var inputFeedback = $(this).parents().find('#form-password-rules');
        inputFeedback.hide();
        var buttonContainer = $(this).parent('div');
        var button = $(this);
        button.css('color', 'transparent');
        button.attr('disabled', 'true');
        buttonContainer.spinner().start();

        if (clientSideValidation.checkPasswordContainsEmail(form) === false) {
            clientSideValidation.checkMandatoryField(form);
        }

        if (!form.find('input.is-invalid').length && (registerForm.length > 0 ? registerForm.val() === 'false' : true)) {
            registerForm.val(true);
            $.ajax({
                url: url,
                type: type,
                data: formdata,
                success: function (data) {
                    button.removeAttr('style');
                    buttonContainer.spinner().stop();
                    if (!data.success) {
                        button.prop('disabled', false);
                        if (data.invalidForm) {
                            var formValidation = require('base/components/formValidation');
                            formValidation($this, data);
                            form.trigger('login:error', data);
                        } else {
                            $('.b-registration-error').removeClass('hide');
                            var errorMsg = $('[data-analytics-track="error-span"]').text().trim() + ' ' + $('[data-analytics-track="error-link"]').text().trim();
                            $('body').trigger('register:error', { errorMsg: errorMsg });
                        }
                        registerForm.val(false);
                    } else {
                        form.trigger('login:success', data);
                        var customerEmail = form.find('[name="dwfrm_profile_customer_email"]').val();
                        $('body').trigger('register:success:analytics', {
                            customerNo: data && data.customerNo,
                            addToEmailList: data && data.addToEmailList,
                            email: customerEmail
                        });
                        if (data && data.addToEmailList) {
                            var isMobileView = layout.isMobileView();
                            $('body').trigger('global:emailSubscribeSuccess', {
                                email: customerEmail,
                                merkleCode: isMobileView ? 'registerEmailOptInForMobile' : 'registerEmailOptInForDesktop',
                                signupLocation: isMobileView ? 'registerEmailOptInForMobile' : 'registerEmailOptInForDesktop',
                                emailHashed: ''
                            });
                        }
                        if ('loyaltyGatedModal' in data) {
                            $('body').trigger('loyalty:enroll', {
                                type: 'genericLink',
                                loyalty: data.loyaltyGatedModal,
                                action: data.loyaltyGatedModal ? 'joined-yes' : 'joined-no',
                                member: 'new_member',
                                points_earned: 0
                            });
                        }
                        if (data.shouldRedirect || $('.js-login-in-page').length > 0 || $('.b-order-confirmation').length > 0 || 'loyaltyGatedModal' in data) {
                            location.href = data.redirectUrl;
                        } else {
                            setRegisteredCookie();
                            window.location.reload();
                        }
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        button.prop('disabled', false);
                        button.removeAttr('style');
                        buttonContainer.spinner().stop();
                    }
                }
            });
        } else {
            if ($('#registration-form-password').val().length) {
                inputFeedback.show();
            }
            button.prop('disabled', false);
            button.removeAttr('style');
            buttonContainer.spinner().stop();
        }
        return false;
    });
}

/**
 * Events after open modal
 */
function registerCreateAccountEvents() {
    $('body').on('login:afterCreateAccountModal', function () {
        submitCreateAccount();
    });
}

module.exports = {
    registerCreateAccountEvents: registerCreateAccountEvents,
    openCreateAccountModal: coreRegister.openCreateAccountModal,
    openAccountCreatedModal: coreRegister.openAccountCreatedModal,
    consecutiveSpaceValidator: coreRegister.consecutiveSpaceValidator,
    openRegisterOnPageLoad: coreRegister.openRegisterOnPageLoad
};
