/* eslint-disable class-methods-use-this */

'use strict';

import Component from 'org/components/core/Component';
import intlTelInput from 'intl-tel-input';
import * as translations from 'intl-tel-input/build/js/i18n';

export default class PhoneNumberField extends Component {
    /**
     * Initialize component
     */
    init() {
        super.init();

        let locale = this.$el[0].dataset.currentLocale.split('_');
        let searchLabel = this.$el[0].dataset.searchLabel;
        let currentCountry = (locale[1] || 'gb').toLowerCase();
        let input = document.querySelector('#phone-input');
        let translatedLabels = window.localizedCountryCodes || translations[locale[0]] || { };

        Object.assign(translatedLabels, {
            searchPlaceholder: searchLabel
        });

        const iti = intlTelInput(input, {
            utilsScript: intlTelInput.utils,
            initialCountry: currentCountry,
            i18n: translatedLabels,
            useFullscreenPopup: false,
            separateDialCode: true
        });

        this.selectors = {
            phoneNumberField: 'phone-field'
        };

        let searchBox = document.getElementsByClassName('iti__search-input')[0];

        if (searchBox) {
            let observer = new MutationObserver(this.onNoCountryCodeFound);
            let countriesList = $('.iti__country-list')[0];

            observer.observe(countriesList, {
                childList: true
            });
        }

        $('#phone-input').on('change', () => {
            this.setPhoneNumber(this.getFormattedNumber($('#phone-input').val()));
        });

        input.addEventListener('countrychange', () => {
            this.onCountryCodeSelection(iti);
        });

        this.appendCountryErrorDiv(this.$el[0].dataset.noResultError);
        this.onCountryCodeSelection(iti);
    }

    /**
     * Add an element to the search result for displaying error when no country is found
     * on searching the country code. Since the library intlTelInput, does not provide
     * this feature, we add an error message manually.
     *
     * @param {string} errorMsg The error message to display
     */
    appendCountryErrorDiv(errorMsg) {
        let searchContent = document.getElementsByClassName('iti__dropdown-content')[0];

        if (searchContent) {
            let noResultError = document.createElement('div');

            noResultError.classList.add('nocountry');
            noResultError.classList.add('hide');
            noResultError.innerHTML = '<span>' + errorMsg + '</span>';

            searchContent.appendChild(noResultError);
        }
    }

    /**
     * Event handler to show the error message when no country code found on search
     * results. It will also hide the element when the country list is generated
     * with country codes.
     *
     * @param {Object} records The DOM records to listen and observe to
     */
    onNoCountryCodeFound(records) {
        let noResultError = document.getElementsByClassName('nocountry')[0];

        if (records.length === 1) {
            noResultError.classList.remove('hide');
        } else {
            noResultError.classList.add('hide');
        }
    }

    /**
     * Format the phone number to remove any '00' in the starting
     *
     * @param {string} phoneNumber The phone number value to format
     * @return {string} The formatted phone number
     */
    getFormattedNumber(phoneNumber) {
        if (phoneNumber.indexOf('00') === 0) {
            return phoneNumber.substring(2, phoneNumber.length);
        }

        return phoneNumber;
    }

    /**
     * Event handler to set country code in the hidden input type which submits
     * to SFSS with the form.
     *
     * @param {Object} iti The Iti object which we get after initializing the object.
     */
    onCountryCodeSelection(iti) {
        if (iti.getSelectedCountryData().dialCode) {
            $('input[name="Country_Code__c"]')[0].value = '+' + iti.getSelectedCountryData().dialCode;
        }

        this.setPhoneNumber(this.getFormattedNumber($('#phone-input').val()));
    }

    /**
     * Event handler to set phone number in the hidden input type which submits.
     *
     * @param {string} phoneNumber The phone number value to set to the hidden input type.
     */
    setPhoneNumber(phoneNumber) {
        $('input[name="phone"]')[0].value = phoneNumber;
    }
}
